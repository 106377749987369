.custom-drawer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

@media screen and (max-width: 991px) {
  .special-nav-container {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .custom-drawer {
    display: block;
  }
}

.custom-drawer ul {
  list-style: none;
  padding: 0;
}

.custom-drawer ul li {
  margin-bottom: 15px;
}

.custom-drawer ul li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.drawer-toggle {
  display: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .drawer-toggle {
    display: block;
    position: absolute;
    top: -40px;
    right: 20px;
    font-size: 24px;
  }
}

.logo {
  margin-top: 20px;
  height: 50px;
  display: flex;
  align-items: center;
}

.logo img {
  max-height: 100%;
  width: auto;
}

@media (max-width: 767px) {
  .logo img {
    margin-top: -10px;
    max-height: 60px;
  }
}

@media (max-width: 400px) {
  .logo img {
    margin-top: -10px;
    max-height: 70px;
  }
}
